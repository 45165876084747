import React from "react";
import SectionWrapper from "./sectionfive.style";
import FAQToggle from "common/components/FAQs";

const ServiceSectionFive = (props) => {
  return (
    <SectionWrapper id="serviceSectionFive" className="section-s4" style={{background: '#f5f5f5'}}>
      <div className="myContainer">
        <div>
          {props.data.map((section, index) => (
            <div key={index}>
              <h3>{section.title}</h3>
              <p>{section.description}</p>
              <div class="faq-description">
                <FAQToggle faqs={section.faq} />
              </div>
              <p>{section.description2}</p>
            </div>
          ))}
        </div>
      </div>
    </SectionWrapper>
  );
};

export default ServiceSectionFive;
