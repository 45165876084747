import styled from 'styled-components';

const SectionWrapper = styled.div`
  
  @media only screen and (max-width: 1440px) {
    
  }
  @media only screen and (max-width: 767px) {
    
  }
`;

export const Container = styled.div`

  @media only screen and (max-width: 1700px) {
   
  }
  @media only screen and (max-width: 1360px) {
    
  }
  @media only screen and (max-width: 767px) {
    
  }
`;

export const ImageWrapper = styled.div`
 
  @media only screen and (max-width: 1200px) {
   
  }
  @media only screen and (max-width: 991px) {
  
  }
  @media only screen and (max-width: 767px) {

  }
`;

export const TextWrapper = styled.div`
  
  @media only screen and (max-width: 991px) {
    
  }
  @media only screen and (max-width: 767px) {

  }

  h2 {
    @media only screen and (min-width: 1441px) {
      
    }
    @media only screen and (min-width: 1440px) {
      
    }
    @media only screen and (min-width: 767px) {
      
    }
  }

`;

export default SectionWrapper;